<template>
  <div>

    <div v-if="loading || preload">
      <div class="loading-shade"></div>
      <pre-loader></pre-loader>
    </div>
    
    <h1 class="main-title">Welcome to {{ APPINFO.appName }}</h1>
    
    <form ref="register" @submit.prevent="register" novalidate="novalidate" autocomplete="off">
      <!-- Name -->
      <div class="field-wr">
        <div class="grid-1-3-3 max-width-field">
          <vue-select
            name="prefix"
            v-model="model.prefixName"
            :options="prefixOptions"
            label="Prefix"
            label-class="lbl-required"
          />
          <vue-input
            class="txt-input"
            name="first-name"
            type="text"
            autocomplete="false"
            v-model="model.firstName"
            label="First Name"
            label-class="lbl-required"
            max-length="255"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'FirstName')}"
          />
          <vue-input
            class="txt-input"
            name="last-name"
            type="text"
            autocomplete="false"
            v-model="model.lastName"
            label="Last Name"
            label-class="lbl-required"
            max-length="255"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'LastName')}"
          />
        </div>
        <div class="max-width-field">
          <div class="my-2 fs-14 lbl-required">Date of Birth</div>
          <datepicker
            auto-position
            auto-apply
            :max-date="maxDate"
            :start-date="maxDate"
            prevent-min-max-navigation
            :enable-time-picker="false"
            v-model="model.dob"
            label="Date of Birth"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'DateOfBirth')}"
          />
        </div>
        <div class="note">You must be over 18 to bet. We require documents below to verify your age.</div>
      </div>
     
      <hr/>

      <!-- Address -->
      <div class="field-wr">
        <div class="position-relative max-width-field">
          <vue-input
            class="txt-input"
            name="post-code"
            type="text"
            autocomplete="false"
            v-model="model.address.postCode"
            label="Post code"
            max-length="255"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Address.ZIPPostal')}"
          />
          <!-- <div class="zip-input-icon-wrap cursor-pointer" @click="findAddress()">
            <div class="zip-input-icon" :style="{'background-image': 'url('+require('@/assets/header/search.svg')+')'}"></div>
          </div> -->
        </div>
        <div v-if="foundAddresses.length > 0 ">
          <vue-select
            label="Select Address"
            :options="foundAddresses"
            v-model="selectedAddress"
          />
        </div>

        <!-- <div>
          <vue-button @click="toggleAddress" type="button" class="my-4">
            <span v-if="!showAddressForm">Show address details</span>
            <span v-if="showAddressForm">Hide address details</span>
          </vue-button>
        </div> -->

        <collapse-transition>
          <div v-show ="showAddressForm"> 
            <div class="grid-1-1 max-width-field">
              <vue-input
                class="txt-input"
                name="state-province"
                type="text"
                autocomplete="false"
                v-model="model.address.state"
                label="State/Province"
                label-class="lbl-required"
                max-length="255"
                :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Address.StateProvince')}"
              />
              <vue-input
                class="txt-input"
                name="city"
                type="text"
                autocomplete="false"
                v-model="model.address.city"
                label="City"
                label-class="lbl-required"
                max-length="255"
                :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Address.City')}"
              />
            </div>
            <div class="max-width-field">
              <vue-select
                  v-model="model.address.country"
                  :options="API.registerCountries.map(el => el.name)"
                  label="Country"
                  label-class="lbl-required"
                />
            </div>
            <div class="max-width-field">
            <vue-input
              class="txt-input"
              name="address-1"
              type="text"
              autocomplete="false"
              v-model="model.address.address1"
              label="Address 1"
              label-class="lbl-required"
              max-length="255"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Address.AddressLineOne')}"
            />
            <vue-input
              class="txt-input"
              name="ddress-2"
              type="text"
              autocomplete="false"
              v-model="model.address.address2"
              label="Address 2"
              max-length="255"
            />
            </div>
          </div>
        </collapse-transition>
      </div>

      <hr/>

      <!-- Contact info -->
      <div class="field-wr">
        <!-- <div class="label">Contact info</div> -->
        <div class="max-width-field grid-1-1">
          <vue-input
            class="txt-input"
            name="phone"
            type="phone"
            autocomplete="false"
            v-model="model.phone"
            label="Phone"
            label-class="lbl-required"
            max-length="255"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Phone')}"
          />
          <div>
            <vue-input
              class="txt-input"
              name="email"
              type="email"
              autocomplete="false"
              v-model="model.email"
              label="Email"
              label-class="lbl-required"
              max-length="320"
              :right-content="true"
              :update-on-input="true"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Email')}"
            >
              <template v-slot:right>
                  <!-- :cls="!model.email ? 'btnDisable' : isEmailVerified ? 'positive' : 'negative'"  -->
                <vue-button 
                :cls="verifyEmailClass"
                  type="button" 
                  @click="openVerifyEmail" 
                  :disabled="isEmailVerified || !isEmailReadyToVerify"
                  class="ms-1 verify-btn"
                >
                  <div :class="{
                    'positive': isEmailVerified,
                    'negative': !isEmailVerified
                  }">
                  <!-- img/mail-check.svg -->
                  <svg v-if="isEmailVerified" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 18L17 20L21 16M11 19H6.2C5.0799 19 4.51984 19 4.09202 18.782C3.71569 18.5903 3.40973 18.2843 3.21799 17.908C3 17.4802 3 16.9201 3 15.8V8.2C3 7.0799 3 6.51984 3.21799 6.09202C3.40973 5.71569 3.71569 5.40973 4.09202 5.21799C4.51984 5 5.0799 5 6.2 5H17.8C18.9201 5 19.4802 5 19.908 5.21799C20.2843 5.40973 20.5903 5.71569 20.782 6.09202C21 6.51984 21 7.0799 21 8.2V12M20.6067 8.26229L15.5499 11.6335C14.2669 12.4888 13.6254 12.9165 12.932 13.0827C12.3192 13.2295 11.6804 13.2295 11.0677 13.0827C10.3743 12.9165 9.73279 12.4888 8.44975 11.6335L3.14746 8.09863" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <!-- img/mail-x.svg -->
                  <svg v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.8 4C1.86451 4 1.0468 4.46923 0.544325 5.16792C0.20074 5.64567 0 6.23499 0 6.86667V17.1333C0 18.682 1.21964 20 2.8 20H12.2C12.7523 20 13.2 19.5523 13.2 19C13.2 18.4477 12.7523 18 12.2 18H2.8C2.39214 18 2 17.6466 2 17.1333V7.94766L7.77948 14.3096C8.96986 15.6199 11.0301 15.6199 12.2205 14.3096L18 7.94766V12.1333C18 12.6856 18.4477 13.1333 19 13.1333C19.5523 13.1333 20 12.6856 20 12.1333V6.86667C20 6.235 19.7993 5.64567 19.4557 5.16792C18.9532 4.46923 18.1355 4 17.2 4H2.8ZM9.25983 12.9647L2.9327 6H17.0673L10.7402 12.9647C10.3434 13.4015 9.65662 13.4015 9.25983 12.9647ZM16.7071 15.2929C16.3166 14.9024 15.6834 14.9024 15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071L17.5858 19L15.2929 21.2929C14.9024 21.6834 14.9024 22.3166 15.2929 22.7071C15.6834 23.0976 16.3166 23.0976 16.7071 22.7071L19 20.4142L21.2929 22.7071C21.6834 23.0976 22.3166 23.0976 22.7071 22.7071C23.0976 22.3166 23.0976 21.6834 22.7071 21.2929L20.4142 19L22.7071 16.7071C23.0976 16.3166 23.0976 15.6834 22.7071 15.2929C22.3166 14.9024 21.6834 14.9024 21.2929 15.2929L19 17.5858L16.7071 15.2929Z" fill="currentColor"/>
                  </svg>
                </div>
                  {{ isEmailVerified ? 'Verified' : 'Verify'}}
                </vue-button>
                
                <div></div>
                
              </template>
            </vue-input>
            
          </div>

        </div>
      </div>

      <hr/>

      <!-- User Name -->
      <div class="field-wr">
        <!-- <div class="label">User Name</div> -->
        <div class="max-width-field">
          <vue-input
            class="txt-input text-uppercase"
            name="user-name"
            type="text"
            autocomplete="false"
            v-model="model.userName"
            label="User Name"
            label-class="lbl-required"
            max-length="8"
            :container-class="''"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Username')}"
          />
        
        </div>
        <div class="note">Letters and numbers only, max 8 characters</div>
      </div>

      <hr/>

      <div class="field-wr">
        <div class="max-width-field ">
          <vue-select
            v-model="model.currency"
            :options="currencyOptions"
            val-field="number"
            label-field="name"
            label="Account Currency"
            label-class="lbl-required"
          />
        </div>
      </div>
      
      <hr/>

      <!-- Deposit limit -->
      <div class="field-wr">
        <!-- <div class="label">Deposit</div> -->
        <div class="max-width-field grid-1-1">
          <div>
            <vue-input
              class="txt-input"
              name="deposit-limit"
              type="number"
              autocomplete="false"
              v-model="model.depositLimit"
              min="10"
              step="1"
              pattern="\d*"
              :placeholder="currencySymbol"
              label="Deposit Limit"
              label-class="lbl-required"
              :container-class="''"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'DepositLimit')}"
            />
            <div class="note">min {{ currencySymbol }}10 max {{ currencySymbol }}5000</div>
          </div>

          <div>
            <div class="my-2 fs-14 lbl-required">Deposit Limit Time Period</div>
            <div v-for="(opt) in limitOptions" :key="opt" :class="{'form-field-error': fieldsValidation.some(el => el.field === 'DepositLimitPeriod')}" >
              <label>
                <input class="radio geoff-radio" type="radio" :value="opt" v-model="model.depositLimitPeriod">
                <span>{{ opt }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <hr/>

      <!-- security question/answer -->
      <div class="field-wr">
        <!-- <div class="label">Security Question/Answer</div> -->
        <div class="max-width-field grid-1-1">
          <vue-select
            v-model="model.securityQuestion"
            :options="securityQuestions"
            label="Security Question"
            label-class="lbl-required"
          />

          <vue-input
            class="txt-input"
            name="security-answer"
            type="text"
            autocomplete="false"
            v-model="model.securityAnswer"
            label="Security Answer"
            label-class="lbl-required"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'SecurityAnswer')}"
          />
        </div>
      </div>

      <hr/>

      <!-- documents -->
      <div class="field-wr">
        <!-- <div class="label lbl-required">Photo ID</div> -->
        <div class="max-width-field">
          <vue-file-input
            class="file-input"
            type="file"
            name="photo-id"
            :value="model.fileNamePhoto"
            label="Photo ID"
            label-class="lbl-required"
            accept=".png, .jpg, .jpeg, .pdf"
            @change="loadFile($event, 'fileNamePhoto', 'filePhoto')"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'filePhoto')}"
          />
        </div>
        <div class="note">Government-issued photo ID showing your date of birth. (40Mb max)</div>
      </div>
      <div class="field-wr">
        <!-- <div class="label lbl-required">Proof of Address</div> -->
        <div class="max-width-field">
          <vue-file-input
            class="file-input"
            type="file"
            name="proof-of-address"
            :value="model.fileNameAddress"
            label="Proof of Address"
            label-class="lbl-required"
            accept=".png, .jpg, .jpeg, .pdf"
            @change="loadFile($event, 'fileNameAddress', 'fileAddress')"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'fileAddress')}"
          />
        </div>
        <div class="note">Recent Utility Bill or Bank Statement showing your current address. (40Mb max)</div>
      </div>
      <div class="field-wr">
        <!-- <div class="label ">Source of Funding</div> -->
        <div class="max-width-field">
          <vue-file-input
            class="file-input"
            type="file"
            name="source-of-funding"
            :value="model.fileNameFunding"
            label="Source of Funding"
            accept=".png, .jpg, .jpeg, .pdf"
            @change="loadFile($event, 'fileNameFunding', 'fileFunding')"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'fileFunding')}"
          />
        </div>
        <div class="note">Not mandatory, but to support higher deposit levels you might want to confidentially share some proof of your source of funding. (40Mb max)</div>
      </div>

      <hr/>

      <div class="field-wr">
        <div class="max-width-field">
          <vue-input
            class="txt-input input-number-hide-spinner"
            label="Bank Account Number"
            label-class="lbl-required"
            name="deposit-limit "
            type="number"
            v-model="model.bankAccountNumber"
            placeholder="8 Digit Number"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'BankAccountNumber')}"
          />
        </div>
      </div>
      <div class="field-wr">
        <div class="max-width-field">
          <vue-input
            class="txt-input input-number-hide-spinner"
            label="Sort Code"
            label-class="lbl-required"
            name="deposit-limit"
            type="number"
            v-model="model.sortCode"
            placeholder="6 Digit Number"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'SortCode')}"
          />
        </div>
      </div>
      <div class="field-wr">
        <div class="text-container max-width-field">
          <vue-input
            class="txt-input"
            label="IBAN Number"
            name="deposit-limit"
            type="text"
            v-model="model.ibanNumber"
          />
        </div>
        <div class="note">Please provide your bank details for faster withdrawals. It is your responsibility the details provided are accurate. {{ APPINFO.appName }} accept no responsibility for payments made to accounts provided that are incorrect.</div>
      </div>

      <hr/>

      <!-- Promo Code -->
      <div class="field-wr">
        <!-- <div class="label">Promo Code</div> -->
        <div class="max-width-field">
          <vue-input
            class="txt-input"
            name="promo-code"
            type="text"
            autocomplete="false"
            v-model="model.promoCode"
            label="Promo Code"
            :container-class="''"
          />
        </div>
        <div class="note">Please Note: Our 30BACK Offer has now expired</div>
      </div>

      <hr/>
      
      <!-- Checkboxes agreement -->
      <div class="field-wr">
        <div class="label lbl-thin lbl-required">Please Tick Below If:</div>
        <div class="max-width-field">
          <vue-checkbox 
            class="checkbox-input"
            v-model="model.isTermsAccepted"
            description="'terms'"
            name="cbx-terms"
            label-class="w-100"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'TermsAndConditions')}"
          >
            <div class="cbx-label">I AGREE TO THE <a class="geoff-link-accent" href="/docs/Sports Betting Limited Terms and Conditions.pdf" target="_blank" >TERMS AND CONDITIONS</a>.</div>
          </vue-checkbox>
          
        
        </div>
      </div>
      <div class="field-wr">
        <div class="label lbl-thin">Please Tick Below If:</div>
        <div class="max-width-field">
          <vue-checkbox 
            class="checkbox-input"
            v-model="model.isMarketingDeclined"
            description="'terms'"
            name="cbx-marketing"
            label-class="w-100"
          >
            <div class="cbx-label">I DO NOT WANT TO RECEIVE TARGETED MARKETING FROM {{ APPINFO.appName?.toLocaleUpperCase() }}.</div>
          </vue-checkbox>
        </div>
      </div>

      <div v-if="validationErrors.length" class="geoff-text-danger mt-3">
        <div v-for="(err, idx) in validationErrors" :key="idx">
          <div>{{ err }}</div>
        </div>
      </div>

      <VueButton
        cls="main"
        class="w-100 mt-4 fw-500 mb-5"
      >
        Register
        <span v-if="loading" class="spinner-border spinner-border-sm ms-3" role="status"></span>
      </VueButton>
    </form>

    <hr class="my-4 text-secondary"/>
    <div class="d-flex justify-content-center">Already have an account?</div>
    <VueButton
      class="w-100 my-2"
      @click="$router.push({name: 'login'})"
    >
      Login
    </VueButton>

    <verify-email-modal 
      ref="verifyEmailModal"
      @verificationSuccess="verifyEmailSucceess"
    ></verify-email-modal>
   
    <vue-modal ref="successModal" :no-close="true">
      <template v-slot:header>
        <div class="p-3 bg-dark">
          <div class="auth-logo bg-dark" :style="{backgroundImage: 'url('+require('@/assets/logo.png')+')'}"></div>
        </div>
      </template>
      <template v-slot:default>
        <div class="mt-5 text-center">
          <div>
            Thank you for registering with {{ APPINFO.appName }}!
          </div>
          <div> Once your documents have been verified you'll receive an email with your login details.</div>

          <vue-button cls="main" @click="finishRegister" class="mt-5 ms-1">Ok</vue-button>
      </div>

      </template>
    </vue-modal>
  </div>
</template>

<script>
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
import VueInput from "@/components/UI/VueInput";
import VueCheckbox from "@/components/UI/VueCheckbox";
import VueSelect from "@/components/UI/VueSelect";
import VueFileInput from "@/components/UI/VueFileInput";
import Datepicker from '@vuepic/vue-datepicker';
import {useToast} from "vue-toastification";
import moment from 'moment'
import PreLoader from "@/components/UI/PreLoader";
import VerifyEmailModal from "@/components/Auth/VerifyEmailModal";
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'
import { emailRegEx } from "@/http/utils"

export default {
  name: "RegisterForm",
  components: {
    VueModal,
    VueCheckbox,
    VueButton,
    VueInput,
    VueSelect,
    VueFileInput,
    Datepicker,
    PreLoader,
    VerifyEmailModal,
    CollapseTransition
  },
  data: ()=>{
    return {
      preload: true,
      loading: false,
      model: {
        prefixName: 'Mr', // "Mr/Mrs/Ms/Miss"
        firstName: '', // required, sanitize, max 255
        lastName: '', // required, sanitize, max 255
        dob: null, // required, "2005-11-19"
        address: {
          address1: null, // required, max 255
          address2: null, // required, max 255
          country: 'Afghanistan', // required, max 255
          postCode: null, // required, max 255
          city: null, // required, max 255
          state: null, // required, max 255
        },
        phone: null, //required; valid characters: '+' '-' '\s' '(' ')'; min 2; max 255
        email: null, // required, sanitize, max 320
        userName: null, //required, Letters and numbers only, max 8 characters
        currency: null,
        depositLimit: null, // required, min 10, max 5000
        depositLimitPeriod: null, // 'Daily', 'Weekly'
        securityQuestion: null, // list
        securityAnswer: null, // sanitize
        fileNamePhoto: null,
        filePhoto: null, // required, png, jpg or pdf. Size restriction is 40mb
        fileNameAddress: null,
        fileAddress: null, // required, png, jpg or pdf. Size restriction is 40mb
        fileNameFunding: null,
        fileFunding: null, // png, jpg or pdf. Size restriction is 40mb
        promoCode: null, // max 255
        isTermsAccepted: false,
        isMarketingDeclined: false, // required, true
        bankAccountNumber: null, // required
        sortCode: null, // required
        ibanNumber: null,
      },

      emailVerify: null, // email verification success response
      emailVerifiedExpirationInterval: null, // timer for email verification exparation


      /*
      model: {
        prefixName: 'Mr', // "Mr/Mrs/Ms/Miss"
        firstName: 'test', // required, sanitize, max 255
        lastName: 'test', // required, sanitize, max 255
        dob: null, // required, "2005-11-19"
        address: {
          address1: null, // required, max 255
          address2: null, // required, max 255
          country: 'United Kingdom', // required, max 255
          postCode: 'NG10 2BZ', // required, max 255
          city: null, // required, max 255
          state: null, // required, max 255
        },
        phone: '123456789', //required; valid characters: '+' '-' '\s' '(' ')'; min 2; max 255
        email: 'test@test.com', // required, sanitize, max 320
        userName: 'testt', //required, Letters and numbers only, max 8 characters
        depositLimit: 10, // required, min 10, max 5000
        depositLimitPeriod: 'Daily', // 'Daily', 'Weekly'
        securityQuestion: null, // list
        securityAnswer: '', // sanitize
        fileNamePhoto: null,
        filePhoto: null, // required, png, jpg or pdf. Size restriction is 40mb
        fileNameAddress: null,
        fileAddress: null, // required, png, jpg or pdf. Size restriction is 40mb
        fileNameFunding: null,
        fileFunding: null, // png, jpg or pdf. Size restriction is 40mb
        promoCode: null, // max 255
        isTermsAccepted: true,
        isMarketingDeclined: false // required, true
      },
      */

      prefixOptions: ['Mr', 'Mrs', 'Sir', 'Dr', 'Miss'],
      limitOptions: ['Daily', 'Weekly'],
      securityQuestions: [
        'Mothers maiden name',
        'Fathers first name',
        'Name of first pet',
        'Favourite place to visit as a child',
        'Favourite actor, musician or artist'
      ],
      currencyOptions: [],

      maxDate: new Date((new Date()).setFullYear((new Date()).getFullYear()-18)),

      // search: 
      loadingAddresses: false,
      showAddressForm: true,
      foundAddresses: [],
      selectedAddress: null,
      frontErrors: null,
      backErrors: null,
      backValidErrors: null,
      
    }
  },
  setup(){
    const toast = useToast()
    return {toast}
  },
  watch:{
    selectedAddress(val){
      if (!val) {
        return
      }
      const split = this.selectedAddress.split(',')
      
      const address1 = split.slice(0,4).filter(el => !!el.trim())
      const city = split.slice(4,6).filter(el => !!el.trim())
      const state = split[6]

      const res = {
        address1: address1.join(', '),
        city: city.join(', '),
        state: state
      }

      this.model.address.address1 = res.address1
      this.model.address.city = res.city
      this.model.address.state = res.state
      this.model.address.country = 'United Kingdom'
      
    },
    'model.address.country'() {
        this.setCurencyByCountryName()
    }
  },
  computed: {
      validationErrors () {
        return this.fieldsValidation.map(el => el.message)
      },
      fieldsValidation () {
        if (this.frontErrors?.length) {
          return this.frontErrors
        }
        if (this.backErrors?.length) {
          return this.backErrors
        }
        if (this.backValidErrors) {
          const res = Object.keys(this.backValidErrors).map(el => { return {
            field: el,
            message: this.backValidErrors[el].find((el => el))
          }}).flat()
          return res
        }
        return []
      },
      isEmailVerified() {
        return this.emailVerify?.isConfirmed && this.model.email.toLocaleLowerCase() === this.emailVerify?.email?.toLocaleLowerCase()
      },
      isEmailReadyToVerify() {
        return this.model?.email && emailRegEx.test(this.model?.email)
      },
      verifyEmailClass() {
        // email is empty or invalid
        if (!this.isEmailReadyToVerify) {
          return 'disable'
        }
        else if ( this.isEmailVerified) {
          return 'positive'
        }
        else {
          return 'negative'
        }
      },
      currencySymbol() {
        if (!this.model.currency) {
          return '£'
        }
        const result = this.currencyOptions.find(el => el.number == this.model.currency)?.symbol
        return result ? result : '£'
      }

  },
  mounted(){
    this.loadCurrensies()
    // nextTick(()=>document.getElementById('username').focus())
     this.model.securityQuestion = this.securityQuestions[0]
  },
  beforeUnmount() {
    clearInterval(this.emailVerifiedExpirationInterval)
  },
  methods:{

    loadCurrensies() {
      this.API.getCurrenciesRegister()
      .then(res => {
          this.currencyOptions = res
          this.setCurencyByCountryName()
          this.preload = false
        })
      .catch(error => {
        console.log('getCurrenciesRegister ERROR', error)
        setTimeout(()=> {
          this.loadCurrensies()
        }, 5000)
      })
    },

    loadFile(e, fileName, file){
      const vm = this
      let files = e.target.files
      if (!files.length) return
      vm.model[fileName] = files[0].name
      vm.model[file] = files[0]
    },
   
    register(){
      
      const validationResult = this.validateForm()
      if (validationResult.length) {
        this.frontErrors = validationResult
        this.$toast.error('One or more fields are invalid. Please fix and try again.')
        if (validationResult.some(el => ['Address.StateProvince', 'Address.City', 'Address.AddressLineOne'].includes(el.field))) {
          this.showAddressForm = true
        }
        return
      }
      else {
        this.frontErrors = []
      }

      let formData = new FormData()
      if (this.model.prefixName) {
        formData.append('Prefix', this.model.prefixName)
      }
      if (this.model.firstName) {
        formData.append('FirstName', this.model.firstName)
      }
      if (this.model.lastName) {
        formData.append('LastName', this.model.lastName)
      }

      // format YYYY-MM-DD
      if (this.model.dob) {
        formData.append('DateOfBirth', moment(this.model.dob).format('YYYY-MM-DD'))
      }
      // address
      if ( this.model.address.address1) {
        formData.append('Address.AddressLineOne', this.model.address.address1)
      }
      if (this.model.address.address2) {
        formData.append('Address.AddressLineTwo', this.model.address.address2)
      }
      if ( this.model.address.city) {
        formData.append('Address.City', this.model.address.city)
      }
      if ( this.model.address.state) {
        formData.append('Address.StateProvince', this.model.address.state)
      }
      if ( this.model.address.postCode) {
        formData.append('Address.ZIPPostal', this.model.address.postCode)
      }
      if ( this.model.address.country) {
        formData.append('Address.Country', this.model.address.country)
      }

      if ( this.model.phone) {
        formData.append('Phone', this.model.phone)
      }
      if ( this.model.email) {
        formData.append('Email', this.model.email)
      }
      if ( this.model.userName) {
        formData.append('Username', this.model.userName)
      }
      if ( this.model.depositLimit) {
        formData.append('DepositLimit', this.model.depositLimit)
      }
      if ( this.model.depositLimitPeriod) {
        formData.append('DepositLimitPeriod', this.model.depositLimitPeriod)
      }

      if ( this.model.securityQuestion) {
        formData.append('SecurityQuestion', this.model.securityQuestion)
      }
      if ( this.model.currency) {
        formData.append('PaymentCurrency.Number', this.model.currency)
      }
      if ( this.model.securityAnswer) {
        formData.append('SecurityAnswer', this.model.securityAnswer)
      }
      // docs
      if(this.model.filePhoto) {
        formData.append('PhotoID', this.model.filePhoto)
      }
      if(this.model.fileAddress) {
        formData.append('ProofOfAddress', this.model.fileAddress)
      }
      if(this.model.fileFunding) {
        formData.append('SourceOfFunding', this.model.fileFunding)
      }

      if(this.model.bankAccountNumber) {
        formData.append('BankAccountNumber', this.model.bankAccountNumber)
      }
      if(this.model.sortCode) {
        formData.append('SortCode', this.model.sortCode)
      }
      if(this.model.ibanNumber) {
        formData.append('IBANNumber', this.model.ibanNumber)
      }

      if(this.model.promoCode) {
        formData.append('PromoCode', this.model.promoCode)
      }
      formData.append('DenyMarketing', this.model.isMarketingDeclined)
      formData.append('TermsAndConditions', this.model.isTermsAccepted)

      this.loading = true
      let ip = ''
      this.API.ipifyGetIp()
        .then(ipResp => {
          ip = ipResp?.ip
        })
        .catch(() => {
          ip = ''
        })
        .finally(() => {
          this.API.register(formData, this.$route.meta?.referral, ip, this.emailVerify.id)
            .then(resp => {
              console.log('registration success', resp)
              this.$refs.successModal.open()
              this.backErrors = null
              this.backValidErrors = null
            })
            .catch((error)=>{
              this.backErrors = error.response.data?.errorMessages
              if (error.response.data?.errorMessages?.some(el => { el.errorType === 4})) {
                // clear email verification 
                this.emailVerify = null
              }
              this.backValidErrors = error.response.data?.errors
              this.$toast.error('One or more fields are invalid. Please fix and try again.')
            })
            .finally(() => {
              this.loading = false
            })
        })

    },

    validateForm() {
      const errors = []

      // NAME
      if (!this.model.firstName) {
        errors.push({
          field: 'FirstName',
          message: 'First Name is required.'
        })
      }
      if (!this.model.lastName) {
        errors.push({
          field: 'LastName',
          message: 'Last Name is required.'
        })
      }
      if (!this.model.dob) {
        errors.push({
          field: 'DateOfBirth',
          message: 'Date of Birth is required.'
        })
      }

      // PHONE
      if (!this.model.phone) {
        errors.push({
          field: 'Phone',
          message: 'Phone is required.'
        })
      }
      else {
        const re =  /^[0-9+-\s)(]+$/i // characters
        if (!re.test(this.model.phone)) {
          errors.push({
            field: 'Phone',
            message: 'Phone number must contains only numbers and + - ( ) characters.'
          })
        }
        const renum = /(\D*\d){4,}/i // 4 nums
        if (!renum.test(this.model.phone)) {
          errors.push({
            field: 'Phone',
            message: 'Phone number is invalid (min 4 numbers).'
          })
        }

      }

      //EMAIL
      if (!this.model.email) {
        errors.push({
          field: 'Email',
          message: 'Email is required.'
        })
      }
      else {
        //eslint-disable-next-line
        const re =  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if ( !re.test(this.model.email)) {
          errors.push({
            field: 'Email',
            message: 'Please, enter valid email.'
          })
        }
        if (!this.isEmailVerified) {
          errors.push({
            field: 'Email',
            message: 'Email is not verified.'
          })
        }

      }

      //ADDRESS
      // if (!this.model.address.postCode) {
      //   errors.push({
      //     field: 'Address.ZIPPostal',
      //     message: 'Post Code is required.'
      //   })
      // }
      if (!this.model.address.state) {
        errors.push({
          field: 'Address.StateProvince',
          message: 'State/Province is required.'
        })
      }
      if (!this.model.address.city) {
        errors.push({
          field: 'Address.City',
          message: 'City is required.'
        })
      }
      if (!this.model.address.address1) {
        errors.push({
          field: 'Address.AddressLineOne',
          message: 'Address 1 is required.'
        })
      }

      // USERNAME
      if (!this.model.userName) {
        errors.push({
          field: 'Username',
          message: 'User Name is required.'
        })
      }
      else {
        const re =  /^[a-z0-9]+$/i // letters and numbers
        if (!re.test(this.model.userName)) {
          errors.push({
            field: 'Username',
            message: 'User Name must contains only lettes and numbers.'
          })
        }
        if (this.model.userName?.trim()?.length > 8 || this.model.userName?.trim()?.length < 6) { // length between 6 and 8
          errors.push({
            field: 'Username',
            message: 'User Name must be min 6 and max 8 characters.'
          })
        }
      }

      // Currency
      if (!this.model.currency) {
        errors.push({
          field: 'Currency',
          message: 'Currency is required.'
        })
      }

      // DEPOSIT
      if (!this.model.depositLimit) {
        errors.push({
          field: 'DepositLimit',
          message: 'Deposit Limit is required.'
        })
      }
      else {
        const value = parseInt(this.model.depositLimit)
        if (value < 10 || value > 5000) {
          errors.push({
            field: 'DepositLimit',
            message: `Deposit Limit must be min ${this.currencySymbol}10 and max ${this.currencySymbol}5000.`
          })  
        }
        if (!Number.isInteger(+this.model.depositLimit)) {
          errors.push({
            field: 'DepositLimit',
            message: 'Deposit Limit must be a valid integer.'
          }) 
        }
      }

      if (!this.model.depositLimitPeriod) {
        errors.push({
          field: 'DepositLimitPeriod',
          message: 'Deposit Limit Period is required.'
        })
      }

      // SECURITY ANSWER
      if (!this.model.securityAnswer) {
        errors.push({
          field: 'SecurityAnswer',
          message: 'Security Answer is required.'
        })
      }

     // FILES
     if (!this.model.filePhoto) {
        errors.push({
          field: 'filePhoto',
          message: 'Photo ID  is required.'
        })
      }
      else {
        if(this.model.filePhoto.size/ 1024 / 1024 > 40) {
          errors.push({
            field: 'filePhoto',
            message: 'Photo ID file must be 40Mb max.'
          })
        }

        // validate extention
        const ext = this.model.fileNamePhoto.split('.').pop()
        if(!['png', 'jpg', 'jpeg', 'pdf'].includes(ext)) {
          errors.push({
            field: 'filePhoto',
            message: 'Photo ID file must be a png, jpg, jpeg or pdf file.'
          })
        }
      }

      if (!this.model.fileAddress) {
        errors.push({
          field: 'fileAddress',
          message: 'Proof of Address  is required.'
        })
      }
      else {
        if(this.model.fileAddress.size/ 1024 / 1024 > 40) {
          errors.push({
            field: 'fileAddress',
            message: 'Proof of Address file must be 40Mb max.'
          })
        }
         // validate extention
        const ext = this.model.fileNameAddress.split('.').pop()
        if(!['png', 'jpg', 'jpeg', 'pdf'].includes(ext)) {
          errors.push({
            field: 'fileAddress',
            message: 'Proof of Address file must be a png, jpg, jpeg or pdf file.'
          })
        }
      }

      if (this.model.fileNamePhoto && this.model.fileNameAddress && this.model.fileNamePhoto === this.model.fileNameAddress) {
        errors.push({
          field: 'filePhoto',
          message: ''
        })
        errors.push({
          field: 'fileAddress',
          message: 'We need two forms please. One to prove your identification, the other a recent utility bill to prove your address.'
        })
      }

      if (this.model.fileFunding) {
        if(this.model.fileFunding.size/ 1024 / 1024 > 40) {
          errors.push({
            field: 'fileFunding',
            message: 'Source of Funding file must be 40Mb max.'
          })
        }
         // validate extention
        const ext = this.model.fileNameFunding.split('.').pop()
        if(!['png', 'jpg', 'jpeg', 'pdf'].includes(ext)) {
          errors.push({
            field: 'fileFunding',
            message: 'Source of Funding file must be a png, jpg, jpeg or pdf file.'
          })
        }
      }

      // BANK ACCOUNT INFO
      if (!this.model.bankAccountNumber) {
        errors.push({
          field: 'BankAccountNumber',
          message: 'Bank Account Number is required.'
        })
      }
      if (!this.model.sortCode) {
        errors.push({
          field: 'SortCode',
          message: 'Sort Code is required.'
        })
      }

      // TERMS
      if (!this.model.isTermsAccepted) {
        errors.push({
          field: 'TermsAndConditions',
          message: "You must agree to terms and conditions to continue!"
        })
      }

      return errors;
    },

    findAddress(){
      let vm = this
      const postCode = this.model.address.postCode
      if(postCode?.length > 4){
        vm.loadingAddresses = true
        this.API.getAddressByZipcode(postCode)
        .then(res => {
          if (res.data.addresses){
            vm.foundAddresses = res.data.addresses
            vm.selectedAddress = res.data.addresses[0]
          }
        })
        .catch(error => {
          console.log('zip search error', error)
          vm.selectedAddress = null
          vm.foundAddresses = []
          vm.$toast.warning("Address not found. Please recheck your postcode or enter address manually.")
          vm.showAddressForm = true

        })
        .finally(()=>vm.loadingAddresses=false)
      } else {
        vm.$toast.warning("Postal Code is too short!")
      }
    },

    setCurencyByCountryName() {
      const currencyNumber = this.API.registerCountries.find(el => el.name === this.model.address.country)?.defaultCurrency
      const currency = this.currencyOptions.find(el => el.number === currencyNumber)
      if (currency) {
        this.model.currency = currency.number
      }
      // set default if empty
      if (!this.model.currency && this.currencyOptions?.length) {
        this.model.currency = this.currencyOptions[0]?.number
      }
    },

    finishRegister() {
      this.$router.push({ name: 'home' })
    },
    toggleAddress() {
      this.showAddressForm = !this.showAddressForm;
    },

    openVerifyEmail() {
      this.$refs.verifyEmailModal.open(this.model.email) //, this.model.verifySessionId
    },

    verifyEmailSucceess(data) {
      this.emailVerify = data
      // set expiration timer
      this.setEmailVerificationTimer()
      this.$refs.verifyEmailModal.close()
    },

    setEmailVerificationTimer() {
      clearInterval(this.emailVerifiedExpirationInterval)
      const msLeft = Math.ceil(this.emailVerify?.expiredSec) * 1000
      this.emailVerifiedExpirationInterval = setInterval(()=>{
        this.emailVerify = null
        clearInterval(this.emailVerifiedExpirationInterval)
      }, msLeft)
    },
  }
}
</script>

<style scoped lang="scss">

 .main-title {
  padding: 0;
  margin-top: 0;
  text-align: center;
  font-size: 1.5rem;
 }

.form-page {
  .header {
    .title {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .section {
    margin-bottom: 2rem;
    * {
      margin-bottom: 0.5rem;
    }
  }
  .subheader {
      font-weight: 700;
  }
  .question {
    font-style: italic;
  }
}

.field-wr {
  margin-bottom: 1rem;
  .label {
    font-weight: 700;
    margin-bottom: 0.5rem;

    &.lbl-thin {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .field-label {
    font-size: 14px;
    line-height: 17px;
  }
  .note {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--geoff-txt-info);
  }
  .radio {
    margin-right: 0.5rem;
  }
  // file input
  .file-input {
    .file-label {
      padding: 0.5rem 4rem 0.5rem 1rem !important;
    }
  }
}

.grid-1-3-3 {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr 3fr 3fr;
  
  @media only screen and (max-width: 800px){
    grid-template-columns: 1fr;
  }
}
.grid-1-1 {
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr 1fr;
  
  @media only screen and (max-width: 800px){
    grid-template-columns: 1fr;
  }
}

.zip-input-icon-wrap{
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 0 4px 4px 0;
  background-color: #FFD60A;
  display: flex;
  justify-content: center;
  align-items: center;

  .zip-input-icon{
    height: 1.5rem;
    width: 1.5rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(0) drop-shadow(0px 0px 0px black);
  }
}

.auth-logo{
  height: 2rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.verify-btn {
  max-width: fit-content;
}
</style>